@import '../../variables.css';

.Modal{
  position: absolute;
  z-index: 100;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 2px;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #d0d7de;
  border-radius: 3px;
  box-shadow: 0px 8px 18px 1px rgba(0,0,0,0.35)

}

.Modal.Dark{
  border: none;
  background-color: #2e2e2e;
  box-shadow:5px 5px 6px 0px rgba(0,0,0,0.8);
  color: white
}

.ModalList{
  list-style: none;
  margin:0;
  padding: 0;
}

.ModalListLi{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
  width: 137px;
  padding: 5px;
}

.ModalListLi:hover{
  background-color:  #f1f3f4;
  cursor: pointer;
}

.ModalListLi.Dark:hover{
  background-color:  #474747;
  cursor: pointer;
}

.Top-Mid{
  top: 5vh;
  width: 148px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.Top-Rigth{
  top: 5vh;
  width: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

._2{
  height: 80px;
}

._3{
  height: 120px;
}

._4{
  height: 140px;
}

@media (max-width: 575.98px){
  .Top-Mid{
    top: 8.5vh;
    right: 4.5vw;
    width: 148px;
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
}