@import '../../../variables.css';

.FirstEventModal{
  position: absolute;
  z-index: 100;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 2px;
  top:30vh;
  right: 40vw;
  height: 200px;
  width: 450px;
  list-style: none;
  background-color: #ffff;
  background-clip: padding-box;
  border: 1px solid #d0d7de;
  border-radius: 6px;
  box-shadow: var(--color-shadow-large);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FirstEventModal.Dark{
  background-color: #2e2e2e;
  border: 1px solid #2e2e2e;
}

.FirstEventModalHeader{
  font-size: 25px;
  width: 420px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FirstEventModalBody{
  color: var(--global-color);
  width: 420px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FirstEventModalBody.Dark{
  color: white;
}

.FirstEventModalBodySpan{
  text-align: center;
}

.FirstEventModalFooter{
  height: 60px;
  width: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 575.98px){
  .FirstEventModal{
    position: absolute;
    top:30vh;
    right: 1vw;
    height: 200px;
    width: 400px;

  }
  
}