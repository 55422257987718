@import '../../variables.css';

.LandingScreen{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LandingPageFooter{
  display: flex;
  margin-top: 6px;
  font-size: 14px;
  color: #818181;
}

@media (max-width: 575.98px){
  .LandingPageFooter{
    display: none;
  }
}