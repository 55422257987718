@import '../../variables.css';

.PreferencesTitle{
  font-size: 20px;
  font-weight: 600;
  color: var(--global-color);
  margin: 20px 0px 20px 0px;
}

.CreateBarCheckBoxContainer{
  display: flex;
  flex-direction: column;
}


.CheckBoxContainer{
  display: flex;
  align-items: center;
  width: 120px;
  height: 40px;
}

.CheckBoxLabel{
  margin-left: 20px;
  font-weight: 500;
  color: var(--global-color);
}

.CheckBox{
  -webkit-appearance: none;
	background-color: #fafafa;
	border: 1px solid #cacece;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
	padding: 9px;
	border-radius: 3px;
	display: inline-block;
	position: relative;
}

.CheckBox:checked {
	background-color: #e9ecee;
	border: 1px solid #adb8c0;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
	color: #99a1a7;
}

.CheckBox:checked:after {
	content: '\2714';
	font-size: 16px;
	position: absolute;
	top: 0px;
	left: 3px;
	color: white;
}

.CheckBox.event:checked{
  background-color:  #34a853;
}

.CheckBox.meeting:checked{
  background-color: var(--meeting-color);
}

.CheckBox.task:checked{
  background-color: var(--task-color);
}

.CheckBox.reminder:checked{
  background-color: var(--reminder-color);
}

.CheckBox.appointment:checked{
  background-color: var(--appointment-color);
}
