@import '../../variables.css';

.CreateBarContainer{
  background-color: #ffff;
  height: 95vh;
  width: 20vw;
  border-right: 1px solid #dadce0;
  display: flex;
  flex-direction: column;
}

.CreateBarContainer.Dark{
  background-color: #212121;
  border-right: 1px solid #121212;
}

.CreateBarContainerHeader{
  width: 20vw;
  height: 19vh;
  background-color: #ffff;
  display: flex;
  flex-direction: column;
}

.CreateBarContainerHeader.Dark{
  background-color: #212121;
  border-bottom: 1px solid #30363d;
}

.CreateBarContainerHeaderButton{
  width: 18vw;
  padding: 10px;
}

.CreateBarContainerBody{
  width: 20vw;
  height: 64vh;
  margin-left: 20px;
}

.CreateBarContainerBody.Dark{

}

.AuthorCreditContainer{
  height: 40vh;
  width: 15vw;
  display: flex;
  flex-direction: column;
}

.CreateBarContainerFooter{
  width: 20vw;
  height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.CalendarVersion{
  color: var(--global-color);
  font-weight: 500;
  margin-left: 20px;
}

.UpComming{
  color: var(--global-color);
  font-weight: 400;
}

.CreateBarNotLoggedIn{
  height: 25vh;
  width: 20vw;
  display: flex;
  flex-direction: column;
}

.CreateBarNotLoggedInDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.CreateBarWarning{
  height: 20px;
  width: 20px;
}

.CreateBarWarningP{
  margin: 0;
  padding: 0;
}

.AuthorCredit.Dark{
  color: #c9d1d9;
}

.CreateBarContainerBodyTitle{
  font-size: 20px;
  font-weight: 600;
  color: var(--global-color);
  margin: 20px 0px 20px 0px;
}


@media (max-width: 575.98px){
  .CreateBarContainer{
    display: none;
  }
}