@import '../../variables.css';

.Footer{
  display: none;
}

@media (max-width: 575.98px){
  .Footer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 100vw;
    border-top: 1px solid #dadce0;
  }
}