@import '../../variables.css';

.Button{
  box-shadow: 0 2px 0 #00000014;
  color: #fff;
  text-shadow: 0 -1px 0 #00000026;
  border: 1px solid #0000;
  border-radius: 5px;
  box-shadow: 0 1px 1px #0000000d;
  font-weight: 600;
  width: 150px;
  height: 35px;
  padding: 8px 16px;
  transition: all .1s;
  cursor: pointer;
  font-size: medium;
}

.RedButton{
  background: #D93025;
}

.BlueButton{
  background: #14AAF5;
  margin-left: 1vw;
}

.DarkBlueButton{
  background: #1a73e8;
}

.GreenButton{
  background: #2da44e;
}

.WhiteButton{
  background: #ffff;
  border: 1px solid #dadce0 !important;
  height: 33px;
  color: black;
  font-weight: 500;
  text-shadow: none;
}


.WhiteButton:hover{
  background-color:  #f1f3f4;
}

.WhiteButtonCreate{
  background: #ffff;
  border: 1px solid #dadce0 !important;
  height: 33px;
  color: black;
  font-weight: 700;
  text-shadow: none;
}

.DarkMode{
  height: 33px;
  font-weight: 500;
  text-shadow: none;
  background-color: #0d1117;
  border: 1px solid #25282f;
  color: #c9d1d9;
}

.DarkMode:hover{
 background-color: #21262d;
 border: 1px solid #373b42;
 cursor: pointer;
}


@media (max-width: 575.98px){
  .Button{
    padding: 0px 0px 0px 0px;
  }

  .WhiteButton{
    width: 70px;
  }
  
  .WhiteButton2{
    width: 80px;
    background: #ffff;
    border: 1px solid #dadce0 !important;
    height: 33px;
    color: black;
    font-weight: 500;
    text-shadow: none;
  }

  .WhiteButtonCreate{
    display: none;
  }
}