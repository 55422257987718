@import '../../variables.css';

.InscriptionContainer{
  height: auto;
  min-height: 500px;
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dadce0;
  border-radius: 8px;
}

.Logo{
  height: 100px;
  width: 200px;
  font-family: 'Orbitron', sans-serif;
}

.InscriptionSpan{
  font-size: 25px;
}

.InscriptionFooter{
  height: 100px;
  width: 325px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.InscriptionBlueP{
  color: #1a73e8;
}
.InscriptionBlueP:hover{
  cursor: pointer;
}

.InscriptionInputContainer{
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.InscriptionInput{
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  height: 45px;
  width: 300px;
  padding: 6px 12px;
  font-size: 16px;
}

.PasswordSpan{
  font-size: 14px;
  color: #818181;
}

.InscriptionForm{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

@media (max-width: 575.98px){
  .InscriptionContainer{
    border: none;
    border-radius: none
  }
}