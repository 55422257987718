@import '../../../variables.css';

.UserModalContainer{
  position: absolute;
  z-index: 100;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 2px;
  list-style: none;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #d0d7de;
  border-radius: 3px;
  box-shadow: 0px 8px 18px 1px rgba(0,0,0,0.35);
  height: 25s0px;
  width: 300px;
  top: 6vh;
  right: 2vw;
  display: flex;
  flex-direction: column;
}


.UserModalContainer.Dark{
  background-color: #2e2e2e;
  border: 1px solid #2e2e2e;
}

.UserModalHeader{
  height: 150px;
  border-bottom: 1px solid #dadce0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.UserModalHeader.Dark{
  border-bottom: 1px solid #30363d;;
}

.UserModalHeaderLogo{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background-color: #4285f4;
  border-radius: 50%;
  font-size: 40px;
  font-weight: bolder;
  color: white;
  margin: 10px;
}

.UserModalHeaderLogo.Dark{
  background-color: #0d1117;
  border: 2px solid #529cf0;
  color: #c9d1d9;
}

.UserModalHeaderDetails{
  display: flex;
  flex-direction: column;
  align-items: center;
}


.UserModalHeaderEmail{
  color: var(--global-color);
  margin: 0;
}

.UserModalHeaderEmail.Dark{
  color: white;
}

.UserModalHeaderUserName{
  font-weight: bold;
  color: black;
  margin: 0;
}

.UserModalHeaderUserName.Dark{
  color: white
}

.UserModalBody{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
