@import '../../variables.css';

.SelectionContainer{
  height: 5vh;
  width: 100vw;
  background-color: #ffff;
  border-bottom: var(--border-classic);
  display: flex;
  align-items: center;
  padding: 8px;
}

.SelectionContainer.Dark{
  background-color: #161b22;
  border-bottom: none;
}

.SelectionContainerButtonContainer{
  height: 45px;
  width: 45px;
  margin-right: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SelectionContainerButtonContainer:hover{
  background-color: #f1f3f4;
  cursor: pointer;
}

.SelectionContainerButtonContainer.Dark:hover{
  background-color: #21262d;
}

.ArrowContainer{
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: none;
}

.ArrowContainer:hover{
  background-color: #f1f3f4;
  cursor: pointer;
}

.ArrowContainer.Dark:hover{
 background-color: #383838;
}

.ArrowBigContainer{
  display: flex;
  width: 100px;
}

.MonthBigContainer{
  display: flex;
  width: 200px;
  font-size: 20px;
  font-weight: 500;
  color: var(--global-color);
}

.MonthBigContainer.Dark{
 color: #ffffff;
}


.SelectionContainerArrowNext{
  height: 20px;
  width: 20px;
  transform: rotate(180deg);
}

.SelectionContainerArrowPrevious{
  height: 20px;
  width: 20px;
}

.SelectionContainerNavigationArrowContainer{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 300px
}

.SelectionContainerBurgerButton{
  display: block;
  height: 20px;
  width: 20px;
}

.SelectionContainerBurgerButton.Dark{
  height: 20px;
  width: 20px;
  color: white;
}

.SelectionContainerNavigation{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 400px;
  margin-left: 20px;
}

.SelectionContainerNavigationToday{
  border: 1px solid #dadce0;
  background-color: #ffff;
  color: black;
  height: 31px;
  min-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.SelectionContainerNavigationToday:hover{
  background-color: #f1f3f4;
}

.SelectionContainerNavigationToday.Dark{
  background-color: #0d1117;
  border: 1px solid #25282f;
  color: #c9d1d9;
}

.SelectionContainerNavigationToday.Dark:hover{
  background-color: #21262d;
  border: 1px solid #373b42;
  cursor: pointer;
}

.LogoContainer{
  display: block;
}

.Logo{
  color: inherit;
  height: 30px;
  width: 100px;
  font-family: 'Orbitron', sans-serif;
}

.Logo.Dark{
  filter: invert(94%) sepia(6%) saturate(0%) hue-rotate(58deg) brightness(107%) contrast(105%); 
}

.UserDetailsContainer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 65vw;
}

.UserDetails{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4285f4;
  color: #ffff;
  font-weight: 600;
  font-size: 16px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-left: 50px;
  margin-right: 15px;
}

.UserDetails.Dark{
  background-color: #0d1117;
  border: 2px solid #529cf0;
  color: #c9d1d9;
}

.UserDetails:hover{
 cursor: pointer;
}

.ModeSelector{
  border: 1px solid #dadce0;
  height: 31px;
  min-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.ModeSelector:hover{
  background-color: #f1f3f4;
}

.MonthTitle{
  display: block;
}

.MonthTitleSmallScreen{
  display: none;
}

.SelectionContainerBurgerButtonSmallScreen{
  display: none;
}


@media (max-width: 575.98px){
  .LogoContainer{
    display: none;
  }

  .SelectionContainer{
    height: 10vh;
    justify-content: space-between;
  }

  .SelectionContainerNavigation{
    width: 60vw;
    margin-left: 0;
  }

  .SelectionContainerNavigationArrowContainer{
    width: 40vw;
  }

  .UserDetailsContainer{
    display: none;
  }

  .ArrowBigContainer{
    width: 50px;
  }

  .MonthTitle{
    display: none;
  }

  .MonthTitleSmallScreen{
    display: block;
  }

  .SelectionContainerBurgerButtonSmallScreen{
    display: block;
    height: 20px;
    width: 20px;
  }

  .SelectionContainerBurgerButton{
    display: none;
  }

  .SelectionContainerButtonContainer{
    margin-right: 0px;
    height: 25px;
    width: 25px;
  }

  .SelectionContainer{
    width: 95vw;
  }

  .ArrowContainer{
    width: 25px;
    height: 25px;
  }


.SelectionContainerNavigationToday{
  min-width: 0px;
  width: 60px;
}
}