@import '../../variables.css';

.Event{
  min-height: 20px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
}

.Event.dayEvent{
  min-height: 40px;
  width: 200px;
  margin: 2px
}

.EventDescription{
  margin: 0;
  padding: 0;
}

.Event:hover{
  cursor: pointer;
}

.meeting{
  background-color: var(--meeting-color);
  color: white;
}

.task{
  background-color: var(--task-color);
  color: white;
}

.reminder{
  background-color: var(--reminder-color);
  color: white
}

.event{
  background-color: var(--event-color);
  color: white;
}

.appointment{
  background-color: var(--appointment-color);
  color: white;
}

.EventModalDescription{
  height: 450px;
  width: 650px;
  background-color: #ffff;
  position: absolute;
  z-index: 998;
  top: 20vh;
  right: 30%;
  border: 1px solid #dadce0;
  border-radius: 8px;
  box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.42);
  display: flex;
  flex-direction: column;
}

.EventModalDescription.Dark{
  background-color: #2e2e2e;
  border: 1px solid #2e2e2e;
}

.EventModalDescriptionHeader{
  height: 35px;
  width: 650px;
  background-color: #f1f3f4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
}

.EventModalDescriptionHeader.Dark{
  background-color: #2e2e2e;
}

.CancelButtonContainer{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.CancelButtonContainer:hover{
  background-color: #dddddd;
  cursor: pointer;
}

.CancelButton{
  height: 12px;
  width: 12px;
}

.EventModalDescriptionTitleContainer{
 display: flex;
 justify-content: space-between;
 padding: 18px 0px 0px 52px;
}

.EventModalDescriptionTitle{
  color: var(--global-color);
  margin: 0;
}

.EventModalDescriptionType{
  color: var(--global-color);
  margin: 0;
}

.EventModalDescriptionDescriptionContainer{
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px 0px 52px;
}

.EventModalDescriptionDescription{
  width: 80%;
  height: 160px;
  word-wrap: break-word;
  border-bottom: 1px solid #dadce0;
  border-radius: 5px;
  background-color: #f1f3f4;
  padding: 15px;
}

.EventModalDescriptionTitleSeparator{
  width: 200px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.EventModalDescriptionFooter{
  display: flex;
  justify-content: flex-end;
  padding: 10px 52px 0px 52px;
}

.EventModalDescriptionTypeContainer{
  height: 30px;
  padding: 18px 0px 0px 52px;
  color: var(--global-color);
}


.EventModalDescriptionTypeOf{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-right: 52px;
}

@media (max-width: 575.98px){
  .Event{
    min-height: 20px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1px;
  }
  
  .Event.dayEvent{
    min-height: 40px;
    width: 200px;
    margin: 2px
  }
  
  .EventDescription{
    margin: 0;
    padding: 0;
  }
  
  .Event:hover{
    cursor: pointer;
  }
  
  .meeting{
    background-color: var(--meeting-color);
    color: white;
  }
  
  .task{
    background-color:var(--task-color);
    color: white;
  }
  
  .reminder{
    background-color: var(--reminder-color);
    color: white
  }
  
  .event{
    background-color: #34a853;
    color: white;
  }
  
  .appointment{
    background-color: var(--appointment-color);
    color: white;
  }
  
  .EventModalDescription{
    width: 100vw;
    height: 100vh;
    right: 0;
    top: 0;
    border: none;
    border-radius: 0px 0px 0px 0px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: visibility 0s, opacity 0.5s linear;
  }
  
  .EventModalDescriptionHeader{
    width: 100vw;
    border-radius: 0px 0px 0px 0px;
  }
  
  .EventModalDescriptionTitleContainer{
   display: flex;
   justify-content: space-between;
   padding: 40px 0px 0px 0px;
   width: 100vw;
  }

  .EventModalDescriptionTitle{
    color: var(--global-color);
    margin-left: 40px;
  }


  .EventModalDescriptionTypeOf{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-right: 40px;
  }
  
  .EventModalDescriptionDescriptionContainer{
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 0px 0px;
  }
  
  .EventModalDescriptionDescription{
    width: 80%;
    height: 160px;
    word-wrap: break-word;
    border-bottom: 1px solid #dadce0;
    border-radius: 5px;
    background-color: #f1f3f4;
    padding: 15px;
  }
  
  .EventModalDescriptionTitleSeparator{
    width: 200px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  
  .EventModalDescriptionFooter{
    display: flex;
    justify-content: flex-end;
    padding: 20px 0px 0px 0px;
  }
  
  .EventModalDescriptionTypeContainer{
    padding: 20px 0px 0px 0px;
    width: 100vw;

  }
  
}