@import '../../variables.css';

.Contact{
  height: 25px;
  width: 250px;
  padding: 5px;
  background-color: white;
  border: 1px solid #d0d7de;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.PendingTitle{
  font-size: 20px;
  font-weight: 600;
  color: var(--global-color);
  margin: 20px 0px 20px 0px;
}

.PendingText{
  color: var(--global-color);
  font-size: 16px;
  font-weight: 500;
}

.PendingCheckContainer{
  width: 70px;
  display: flex;
  justify-content: space-evenly;
}

.PendingValidate{
  height: 25px;
  width: 25px;
  color: white;
  border-radius: 50%;
  background-color: var(--event-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.PendingValidate:hover{
  cursor:pointer;
}

.PendingRefuse{
  height: 25px;
  width: 25px;
  color: white;
  border-radius: 50%;
  background-color: var(--reminder-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.PendingRefuse:hover{
  cursor: pointer;
}

.PendingDetails{
  display: none;
}

.Contact:hover .PendingDetails{
  display: block;
  position: absolute;
  z-index: 999;
  top: 81.2vh;
  left: 280px;
  height: 20px;
  width: 250px;
  padding: 5px;
  background-color: #202124;
  color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}