@import '../../variables.css';

.CalendarScreen{
  height: 100vh;
  width: 100vw;
}

.CalendarScreenBody{
  display: flex;
}

.WelcomeContainer{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #141a25;
}


.ErrorCode{
  color: #14a8f3;
  font-size: 10vh;
}

.ErrorMessage{
  color: white;
  font-size: 4vh;
}

.ErrorMessageDescription{
  color: white;
  font-size: 1.5vh;
}

@media (max-width: 575.98px){
  .CalendarScreen{
    height: 107vh;
    width: 100vw;
  }
}