@import '../../variables.css';

.ConnexionContainer{
  height: auto;
  min-height: 500px;
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dadce0;
  border-radius: 8px;
}

.ConnexionForm{
  display: flex;
  flex-direction: column;
}

.ConnexionInputContainer{
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConnexionFooter{
  height: 100px;
  width: 325px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ConnexionInput{
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  height: 35px;
  width: 300px;
  padding: 6px 12px;
  font-size: 16px;
}

.ConnexionBlueP{
  color: #1a73e8;
}

.ConnexionBlueP:hover{
  cursor: pointer;
}

.Logo{
  height: 100px;
  width: 200px;
  font-family: 'Orbitron', sans-serif;
}

.ConnexionSigninP{
  font-size: 25px;
}


@media (max-width: 575.98px){
  .ConnexionContainer{
    border: none;
    border-radius: none
  }
}