@import '../../variables.css';

.ContactSearchTitle{
  font-size: 20px;
  font-weight: 600;
  color: var(--global-color);
  margin: 20px 0px 20px 0px;
}

.ContactSearchContainer{
  height: 150px;
  display: flex;
  flex-direction: column;
}

.ContactSearchInput{
  color: var(--global-color);
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  height: 25px;
  width: 250px;
  font-size: 18px;
  padding: 5px;
}

.Contact{
  display: flex;
  align-items: center;
  height: 25px;
  width: 250px;
  padding: 5px;
  margin-top: 5px;
  border: var(--border-classic);
  border-radius: 4px;
}

.AddContactLogo{
  height: 15px;
}

.CreateContactContactBox{
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.CreateContactLogoBox{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CreateContactLogoBox:hover{
  background-color: #f1f3f4;
  cursor: pointer;
}

.AskContactModal{
  position: absolute;
  z-index: 100;
  height: 100px;
  width: 450px;
  top: 68vh;
  left: 1vw;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 2px;
  background-color: white;
  border: 1px solid #d0d7de;
  border-radius: 8px;
  box-shadow: 0px 8px 18px 1px rgba(0,0,0,0.35);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AskContact{
  color: var(--global-color);
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
}

.AskContactModalMessage{
  color: var(--global-color);
  font-size: 16px;
  font-weight: 500;
  margin: 10px 10px 10px 10px;
}

.NewContactName{
  color: var(--meeting-color)
}
