@import '../../variables.css';

.Toaster{
  height: 25px;
  width: 250px;
  padding: 5px;
  background-color: white;
  border: 1px solid #d0d7de;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Toaster.add{
  background-color: var(--event-color);
}

.Toaster.refuse{
  background-color: var(--reminder-color);
}

.ToasterText{
  color: white;
  font-size: 16px;
  font-weight: 500;
}