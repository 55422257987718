@import '../../variables.css';

.CalendarContainer{
  background-color:#ffff;
  height: 95vh;
  width: 100vw;
  min-width: 80vw;
}

.CalendarContainer.Dark{
  background-color: #010409;
}

.CalendarContainerHeader{
  display: flex;
  justify-content: space-evenly;
  height: 12vh;
}

.CalendarContainerColumnHeader{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 14vw;
  margin-left: none;
}

.CalendarContainerColumnBodyContainer{
  display: flex;
  height: 80vh;
  overflow: scroll;
}

.CalendarContainerColumnBodyContainer::-webkit-scrollbar {
 display: none;
}

.CalendarContainerBodyHourColumn{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4vw;
  height: 1176px;
  margin-left: none;
}

.CalendarContainerColumnBody{
  background-color: #ffff;
  border-right: 1px solid #dadce0;
  width: 14vw;
  height: 1176px;
}

.CalendarContainerColumnBody.Dark{
  background-color: #212121;
  border-right: 1px solid #30363d;
}

.CalendarContainerHourRow{
  height: 48px;
  border-top: 1px solid #dadce0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CalendarContainerHourRow.Dark{
  border-top: 1px solid #30363d;
}

.CalendarContainerHourRow:last-child{
  height: 48px;
  border-top: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
}

.CalendarContainerHourRow.Dark:last-child{
  border-top: 1px solid #30363d;
  border-bottom: 1px solid #30363d;
}

.CalendarContainerHeaderHourColumn{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4vw;
  height: 12vh;
}

.CalendarContainerColunmHeaderTitle{
  color: var(--global-color);
}

.CalendarContainerColunmHeaderTitle.Dark{
    color: #c9d1d9;
}

.CalendarContainerHeaderCurrentDay{
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbbd04dc;
  color: #ffff;
  font-size: 22px;
  font-weight: bold;
  margin-left: none;
}

.CalendarContainerHeaderCurrentDay.Dark{
  background-color: #343941;
  border: 2px solid #529cf0;
  color: #ffffff;
}

.CalendarContainerHeaderCurrentDay:hover{
  background-color: #ffcc33dc;
  cursor: pointer;
}

.CalendarContainerHeaderCurrentDay.Dark:hover{
  background-color: #343941;
  color: #ffffff;
  cursor: pointer;
}

.CalendarContainerHeaderOtherDay{
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
  color: var(--global-color);
  font-size: 22px;
  font-weight: bold;
}

.CalendarContainerHeaderOtherDay.Dark{
  background-color: #010409;
  color: #ffffff;
}

.CalendarContainerHeaderOtherDay:hover{
  background-color: #f1f3f4;
  cursor: pointer;
}

.CalendarContainerHeaderOtherDay.Dark:hover{
  background-color:#5d5d5d;
  cursor: pointer;
}

.DisplayDayContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 4vw;
  height: 13vh;
}

.CalendarContainerHourSpan{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 49px;
  font-size: 12px;
  color: var(--global-color);
}

.CalendarContainerHourSpan.Dark{
  color: #529cf0;
}

.DisplayDayRowContainer{
  width: 100%;
  height: 1176px;
}

.DisplayDayRow{
  height: 48px;
  border-bottom: 1px solid #dadce0;
  display: flex;
  align-items: center;
}

.DisplayDayRow.Dark{
  border-bottom: 1px solid #30363d;;
}

.AddEventDiv{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RoundTimer{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: black;
}

.RoundTimer.Dark{
  background-color: #529cf0;
}

.Timer{
  height: 2px;
  width: 95%;
  background-color: black;
  z-index: 997;
}

.Timer.Dark{
  background-color: #529cf0;
}

@media (max-width: 575.98px){
  .CalendarContainerBodyHourColumn{
    margin-left: 2px;
    width: 8vw;
  }


.DisplayDayContainer{
  width: 15vw;
}

.CalendarContainerHeader{
  height: 15vh;
}


}
