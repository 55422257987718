@import '../../../variables.css';

.CreateEventModal{
  height: 450px;
  width: 650px;
  background-color: #ffff;
  position: absolute;
  z-index: 998;
  top: 20vh;
  left: 19vw;
  border: 1px solid #dadce0;
  border-radius: 8px;
  box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.42);
  display: flex;
  flex-direction: column;
}

.CreateEventModal.Dark{
  background-color: #2e2e2e;
  border: 1px solid #2e2e2e;
}


.CreateEventModalDiv{
  height: 45px;
  width: 650px;
}

.CreateEventModalDivDescription{
  height: 100px;
  padding: 0px 0px 0px 52px;
}

.CreateEventModalDivFooter{
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 52px 0px 0px;
}

.CreateEventModalDivAddGuest{
  height: 45px;
  padding: 0px 0px 0px 52px;
  display: flex;
  align-items: center;
}

.CreateEventModalGuestInput{
  width: 580px;
  height: 25px;
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom: 1px solid #dadce0;
  transition-property: border;
  transition-duration: 0.2s;
}

.CreateEventModalGuestInput:focus{
  border-bottom: 2px solid blue;
}

.CreateEventModalDescriptionInput{
  width: 580px;
  height: 180px;
  font-size: 16px;
  border: none;
  outline: none;
  background-color: #f1f3f4;
  border-bottom: 1px solid #dadce0;
  transition-property: border;
  transition-duration: 0.2s;
  resize: none;
  padding: 15px;
  box-sizing: border-box;
}

.CreateEventModalDescriptionInput.Dark{
  border-bottom: none;
}

.CreateEventModalDescriptionInput:focus{
  border-bottom: 2px solid blue;
}

.CreateEventModalDescriptionInput.Dark:focus{
  border-bottom: none
}


.CreateEventModalDivDate{
  height: 45px;
  padding: 0px 0px 0px 52px;
  display: flex;
  align-items: center;
}

.CreateEventModalTypeContainer{
  height: 45px;
  padding: 0px 0px 0px 52px;
  display: flex;
  align-items: center;
}

.CreateEventModalTypeContainerSmall{
  display: none;
}

.CreateEventModalType{
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
  color: var(--global-color);
  background-color: inherit;
}

.CreateEventModalType:hover{
  color: var(--global-color);
  background-color: #f8f9fa;
  cursor: pointer;
}

.CreateEventModalTypeSelected{
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
  color: #1967d2;
  background-color: #e8f0fe;
  cursor: pointer;
}

.CreateEventModalTypeSelected.Dark{
  color: var(--global-color);
}

.CreateEventModalDivInput{
  display: block;
  height: 45px;
  padding: 18px 0px 0px 52px;
}

.CreateEventModalInput{
  width: 580px;
  height: 35px;
  font-size: 22px;
  border: none;
  outline: none;
  border-bottom: 1px solid #dadce0;
  transition-property: border;
  transition-duration: 0.2s;
  padding: 15px;
  box-sizing: border-box;
}

.CreateEventModalInput:focus{
  border-bottom: 2px solid blue;
}

.CreateEventModalInput.Dark{
  border-bottom: none;
}

.CreateEventModalInput.Dark:focus{
  border-bottom: none
}


.CreateEventModalHeader{
  height: 35px;
  width: 650px;
  background-color: #f1f3f4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
}

.CreateEventModalHeader.Dark{
  background-color: #2e2e2e;
}

.CreateEventModalFooter{
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.CancelButtonContainer{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.CancelButtonContainer:hover{
  background-color: #dddddd;
  cursor: pointer;
}

.CancelButton{
  height: 12px;
  width: 12px;
}

.CreateEventModalDivDateContainer{
  height: 30px;
  width: 100px;
  padding: 0px 5px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: var(--global-color);
  background-color: #f8f9fa;;
}

.CreateEventModalDivDateContainer:hover{
  cursor: pointer;
}

.SelectedDateModal{
  height: 260px;
  width: 300px;
  background-color: #ffff;
  position: absolute;
  z-index: 999;
  top: 182px;
  left: 52px;
  border: 1px solid #dadce0;
  border-radius: 2px;
  box-shadow: 0px 5px 4px -1px rgba(0,0,0,0.31);
  display: flex;
  flex-direction: column;
}

.SelectedDateModalDayHeader{
  display: flex;
  justify-content: space-evenly;
  width: 280px;
  padding: 0px 10px 0px 10px;
}

.SelectedDateModalDay{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 200px;
}

.WeekContainer{
  height: 20px;
}

.SelectedDateModalHeaderSpan{
  height: 20px;
  display: flex;
  align-items: center;
  padding: 10px 30px 0px 30px;
  display: flex;
  justify-content: space-between;
}

.DayLetterSpan{
  font-size: 12px;
  font-weight: 500;
  color : var(--global-color)
}

.DateSelector{
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DateSelector:hover{
 cursor: pointer;
 background-color: #f1f3f4;
}

.ArrowPlus{
  height: 20px;
  width: 20px;
  transform: rotate(180deg);
}

.ArrowMinus{
  height: 20px;
  width: 20px;
}

.ArrowContainer{
  display: flex;
}

.ArrowSingleContainer{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ArrowSingleContainer:hover{
  cursor: pointer;
  background-color: #f1f3f4;
}

.HourSelection{
  width: 110px;
  height: 30px;
  border: none;
  color: var(--global-color);
  background-color: #f8f9fa;
  text-align-last: center;
  border-radius: 5px;
  margin-left: 10px;
}

.CreateEventModalShowButton{
  
  display: block;
}

@media (max-width: 575.98px){
  .CreateEventModalDivDescription{
    height: 100px;
    padding: 20px 0px 0px 0px;
    width: 100vw;
    display: flex;
    justify-content: center;
  }

  .CreateEventModal{
    width: 100vw;
    height: 100vh;
    left: 0vw;
    top: 0;
    background-color: #ffff;
    position: absolute;
    z-index: 998;
    border:none;
    border-radius: none;
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }

  .CreateEventModalDivFooter{
    justify-content: center;
    padding: 20px 0px 0px 0px;
  }
  
  .CreateEventModalDescriptionInput{
    width: 80%;
    padding: 15px;
  }

  .CreateEventModalDivDate{
    padding: 20px 0px 0px 0px;
    justify-content: center;
  }
  
  .CreateEventModalTypeContainer{
   display: none;
  }
  
  .CreateEventModalDivInput{
    display: flex;
    justify-content: center;
    padding: 40px 0px 0px 0px;
  }
  
  .CreateEventModalInput{
    width: 90vw;
    padding: none;
  }
  
  .CreateEventModalHeader{
    height: 35px;
    width: 100vw;
    border-radius: 0px 0px 0px 0px;
  }
  
  .CreateEventModalDivDateContainer{
    padding: none;
  }

  .CreateEventModalShowButton{
    display: none;
  }

  .CreateEventModalTypeContainerSmall{
    height: 45px;
    padding: 20px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}